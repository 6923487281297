<template>
  <div>
    <v-container v-if="userData.role === 'CUST'" fluid class="px-3 px-lg-0">
      <v-row>
        <v-col cols="12" md="4" lg="3">
          <v-row>
            <v-col sm="6" md="12">
              <v-card class="pa-5 pb-6 pa-md-0 pb-md-0" rounded="lg">
                <div v-if="!$vuetify.breakpoint.smAndDown" class="white text-center">
                  <img src="@/assets/images/eksun-logo.png" height="275" class="pa-9" />
                </div>

                <v-list two-line>
                  <v-subheader> Firma Bilgileriniz</v-subheader>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiDomain }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>Cari Unvan</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.company }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiCodeBrackets }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>Müşteri Numarası</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.cari_kod }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-3" />
                  <v-subheader> Satış Temsilciniz</v-subheader>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiAccount }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>Adı Soyadı</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.saldept_name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider inset></v-divider>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiPhone }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>Cep Telefonu</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.saldept_phone }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider inset></v-divider>

                  <v-list-item class="pb-5">
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiAt }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>E-Posta</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.saldept_email }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="8" lg="9">
          <v-row>
            <v-col cols="12" lg="7">
              <v-row style="height: calc(100% + 24px)">
                <v-col cols="12" sm="6">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiPodium }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">Bu Ayki Toplam</div>
                        <div class="text-body-1 white--text font-weight-medium pb-5">Sipariş Tutarı</div>
                        <v-chip outlined color="primary" label>
                          {{ toplamSiparisTutari | currency }}
                        </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiCartArrowDown }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">Bu Ayki Toplam</div>
                        <div class="text-body-1 white--text font-weight-medium pb-5">Sipariş Adedi</div>
                        <v-chip outlined color="primary" label> 12 Adet </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="5">
              <v-card rounded="lg" height="100%">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="text-body-2 py-2">Borç</td>
                        <td class="text-body-2 py-2">
                          <span class="warning--text"> : {{ balance.BORC | currency }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-body-2 py-2">Alacak</td>
                        <td class="text-body-2 py-2">
                          <span class="success--text"> : {{ balance.ALACAK | currency }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-body-2 py-2">Bakiye</td>
                        <td class="text-body-2 py-2">
                          <span class="error--text"> : {{ balance.BAKIYE | currency }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <v-avatar color="accent">
                    <v-icon :size="24" color="white">
                      {{ icons.mdiStar }}
                    </v-icon>
                  </v-avatar>
                  <span class="ml-5 primary--text">
                    <div class="text-caption">Son 1 Yıl İçinde</div>
                    <div class="text-body-1 white--text font-weight-medium">En Çok Satın Aldığınız Ürünler</div>
                  </span>
                  <v-spacer />
                  <v-btn v-if="$vuetify.breakpoint.smAndUp" color="accent" depressed small to="order-tracking">
                    Tüm Siparişleriniz
                  </v-btn>
                </v-card-title>

                <v-data-table
                  :headers="headers"
                  :items="toSipAdetiRec"
                  :items-per-page="10"
                  hide-default-footer
                ></v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <span class="text-caption">2022 © Eksun Gıda Tarım San. Tic. A.Ş</span>
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-chip small label color="grey"> v1.0.1 </v-chip>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { useRouter } from '@core/utils'
import { getCurrentInstance, onMounted, ref } from '@vue/composition-api'
import CustomerList from '@/components/CustomerList.vue'
import {
  mdiAccount,
  mdiAt,
  mdiCalendar,
  mdiCalendarClock,
  mdiCartArrowDown,
  mdiChevronLeft,
  mdiCodeBrackets,
  mdiDomain,
  mdiFilter,
  mdiIp,
  mdiLogin,
  mdiPhone,
  mdiPodium,
  mdiSend,
  mdiStar,
  mdiTrayFull,
} from '@mdi/js'

import { initialAbility } from '@/plugins/acl/config'
import store from '@/store'

export default {
  components: {
    CustomerList,
  },
  setup() {
    // UnRegister on leave

    const userData = JSON.parse(localStorage.getItem('userData'))

    const vm = getCurrentInstance()?.proxy
    const { router } = useRouter()

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    }

    const toSipAdetiRec = ref([])
    const headers = ref([
      {
        text: 'Ürün Kodu',
        align: 'start',
        sortable: false,
        value: 'prd_code',
        width: '150',
      },
      { text: 'Ürün Adı', value: 'baslik', sortable: false },
      {
        text: 'Sipariş Adet',
        value: 'topsipadeti',
        align: 'right',
        sortable: false,
        width: '150',
      },
    ])
    const toplamSiparisTutari = ref(0)
    const sonuc = ref({ create_date: '', last_login: '' })
    onMounted(() => {
      fetchDashboard()
    })
    const balance = ref({})
    const fetchDashboard = () => {
      const method = 'getCustomerDashboardVars'

      store.dispatch('postMethod', { method }).then(response => {
        if (response.error == '0') {
          toSipAdetiRec.value = response.response.toSipAdetiRec
          sonuc.value = response.response.sonuc
          toplamSiparisTutari.value = response.response.toplamSiparisTutari

          balance.value = response.response.balance
        } else {
          logoutUser()
        }
      })
    }

    return {
      userData,
      toSipAdetiRec,
      headers,
      sonuc,
      toplamSiparisTutari,
      balance,

      // icons
      icons: {
        mdiCartArrowDown,
        mdiPodium,
        mdiIp,
        mdiLogin,
        mdiStar,
        mdiTrayFull,
        mdiFilter,
        mdiSend,
        mdiCalendarClock,
        mdiCalendar,
        mdiChevronLeft,
        mdiAt,
        mdiPhone,
        mdiAccount,
        mdiCodeBrackets,
        mdiDomain,
      },
    }
  },
}
</script>
